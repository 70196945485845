@import '../../../game_frame/assets/scss/game_frame';
@import '../../../gam/assets/scss/gam';

body:has(.m-games-overlay) {
  overflow-y: hidden;
}

.m-games-overlay {
  background-color: rgba(18, 22, 28, 0.96);
  z-index: 999;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 20px 0px;
  color: #fff;
  margin-left: 0px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: 1080px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (min-width: 1280px) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @media screen and (min-width: 1336px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (min-width: 1536px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 1920px) {
    padding-left: 140px;
    padding-right: 140px;
  }

  @media screen and (min-width: 2560px) {
    padding: 24px 348px;
  }

  &--close-button {
    position: absolute;
    right: 8px;
    z-index: 500 !important;
    display: none;
    width:92px;
    height: 44px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
    border: 1px solid $squirrel;
    background: #000;
    color: #fff;
    padding: 14px 15px 14px 17px;
    text-decoration: none;

    @media screen and (min-width: 960px) {
      display: flex;
    }

    @media screen and (min-width: 1080px) {
      right: 20px;
    }

    @media screen and (min-width: 1536px) {
      right: 40px;
    }

    @media screen and (min-width: 1920px) {
      right: 140px;
    }

    @media screen and (min-width: 2560px) {
      right: 348px;
    }

    &-icon {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }

    &-text {
      font-family: var(--font-family-heading);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    &:hover {
      background: $squirrel;
      border: 1px solid $squirrel;
    }

    svg path {
      fill: #fff;
    }
  }

  &-large-banner-ads,
  &-small-banner-ads {
    margin-bottom: 20px;
  }

  &--header {
    min-height: 90px;
  }

  &-frame-wrapper {
    margin: 0px;

    @media screen and (min-width: 1536px) {
      margin: 0px 20px 0px;
    }
  }

  &-main {
    display: flex;
    justify-content: flex-start;
    gap: 20px;

    @media screen and (min-width: 1536px) {
      gap: 40px;
    }

    &-content {
      flex-grow: 1;
    }

    &-right-rail-ads {
      display: none;
      position: relative;

      @media screen and (min-width: 1336px) {
        display: flex;
        min-width: 300px;

        .m-gam__container {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 400;
          width: 300px;
        }
      }
    }
  }

  &-separator {
    border: 1px solid;
    color: $white;
    opacity: 0.3;
    margin: 28px 20px 40px;

    @media screen and (min-width: 1080px) {
      margin-left: 0px;
    }

    @media screen and (min-width: 1536px) {
      margin-right: 20px;
      margin-right: 0px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
