@import '../../../base/assets/scss/_base/config';
// Submodule SCSS
@import '../../../video_player_wafer/assets/scss/video_player_wafer';

.m-game-frame {
  text-align: left;
  margin: 0;

  border-radius: 8px;
  overflow: hidden;
  color: $white;
  background-color: $black;

  &.unsupported {
    background: $brand-games;
    color: #fff;
    font-family: var(--font-family-base);
    font-size: 12px;
    margin: 100px auto;
    padding: 5px 10%;
    text-align: center;
    text-transform: uppercase;
    width:50%;
  }

  &.m-game-frame--mobile {
    border-radius: 0;
    margin: 20px 0 0 0 !important;
    height: 100%;

    .game-pre-load {
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;

      .loading-message {
        padding: 5px 10px;
        text-align: center;
        width: 100%;

        & > p {
          margin: 0;
        }
      }

      #games-preroll-player {
        height: 100%;
        width: 100%;

        .video-wrapper{
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 100%;

          .maas-item{
            height: 100%;
          }
          .m-video-wafer{
            height: 100%;
            margin-bottom:0;

            &__player--container{
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    #game-inner {
      height: 100%;
    }

    .m-game-frame {
      &__inner {
        height: 100%;
        .game-pre-load {
          padding: 0;

          .m-video-wafer__player--container{
            margin: 0;
          }
        }

      }
    }
  }

  &.m-game-frame--game-hidden{
    #game-inner,.game-inner-iframe{
      display: none !important;
    }
  }

  &.m-game-frame--ad-hidden{
    .game-pre-load{
      display: none !important;
    }
  }

  &__details {
    background-color: $batcave;
    padding: 32px 32px 28px;

    &--title {
      font-weight: 500;
      font-size: 24px;
      line-height: 1;
      margin: 0 0 24px 0;
    }

    &--icons{
      display: flex;
      justify-content: left;
      flex-direction:row;
      a{
        display: block;
        padding: 0 24px 20px 0;
      }

      svg{
        fill: $grey_hair;
        height: 20px;
        width: 20px;
      }
    }

    &--desc{
      font-weight: 500;
      font-size: 18px;
      line-height: 1.56;
      margin: 0 0 16px 0;
      max-width: 820px;
    }

    &--sponsor{
      font-weight: 500;
      color: $gandalf;
      font-size: 12px;
      line-height: 1.33;
    }
  }

  &__inner{
    position: relative;
    width: 100%;
    height: 0;

    iframe, .game-pre-load{
      border: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .loading-message {
      background: $brand-games;
      color: $white;
      font-size: 12px;
      margin: 0 auto;
      padding: 5px 0;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      > p {
        margin: 0;
      }
    }

    #games-preroll-player{
      height: calc(100% - 25px);
      width: 100%;
      .video-wrapper{
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;

        .maas-item{
          height: 100%;
          width: 100%;
          .m-video-wafer{
            height: 100%;
            width: 100%;
            margin: 0;
            &__player--container{
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

  }
}

.game_g_a_popup {
  &.hide {
    display: none;
  }
  padding: 0;
  margin-bottom: 30px;
  &--container {
    padding: 20px 20px;
    height: 100%;
    text-align: left;
    border-radius: 16px;
    width: 100%;
    box-shadow: $fluffy-pancake;
    display: flex;
    background: $white;
    color: $gotham;
  }

  &--icon, &--close {
    display: flex;
  }

  &--close {
    align-items: flex-start;
  }

  &--content {
    display: flex;
    font-family: var(--font-family-base);
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 20px;
    flex-grow: 1;
  }

  @media screen and (min-width: map-get($breakpoints, xs)) {
    &--container {
      padding: 20px 40px;
      border-radius: 40px;
    }

    &--content {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      padding-left: 40px;
    }
  }

  @media screen and (min-width: map-get($breakpoints, s)) {
    padding: 0px 10%;
    &--close {
      align-items: center;
    }
  }
}
.m-gfd--gameOverlay {
  margin-bottom: 0px !important;
  .m-game-frame {
    background-color: rgba(18, 22, 28, 0.01) !important;
    border-radius: 0px;

    &__details {
      background-color: rgba(18, 22, 28, 0.01) !important;
      padding: 32px 32px 0px 0px;

      @media screen and (max-width: 1080px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &--title {
        font-weight: 700;
        font-family: var(--font-family-heading);
        margin: 0 0 20px 0;
      }

      &--desc {
        font-family: var(--font-family-heading);
        line-height: 28px;
        margin: 0 0 20px 0;
      }

      &--sponsor {
        line-height: 16px;
      }
    }

    &__inner {
      padding-top: 56.25% !important;

      iframe {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }
}
